<script>
  import {
    mdiAlertOutline,
    mdiFilterOutline,
    mdiMagnify,
    mdiMapMarkerOutline,
    mdiPrinterOutline,
    mdiRefresh,
    mdiUndoVariant
  } from "@mdi/js";
  import { defineComponent, ref, watch } from "@vue/composition-api";
  import Vue from "vue";

  import AppLoading from "@/components/base/loading/AppLoading.vue";
  import { snackbar } from "@/components/base/snackbar/useSnackbar";
  import IdrFilterMenu from "@/components/common/filter-menu/DailyLogFilterMenu.vue";
  import { resolveOrganizationLogo } from "@/composables/useOrganizations";
  import { usePagination } from "@/composables/usePagination";
  import useVuetify from "@/helpers/vuetify";
  import { store } from "@/store";
  import themeConfig from "@/themeConfig";
  import PrintDailyLogReport from "@/views/apps/daily-log/PrintDailyLogReport.vue";

  import { useDailyLogInvoice } from "./useDailyLogInvoice";
  import {
    useDailyLogViewList,
    useDailyLogViewListDatatable
  } from "./useDailyLogList";
  export default defineComponent({
    components: {
      IdrFilterMenu,
      AppLoading,
      PrintDailyLogReport
    },
    setup() {
      const { rootThemeClasses } = useVuetify();
      const {
        dailyLogViews,
        dailyLogViewsLoaded,
        dailyLogViewsPending,
        dailyLogViewsLatestQuery,
        findDailyLogViews,
        dailyLogViewsParams
      } = useDailyLogViewList();
      const { selectedNumberOfItemsPerPage, page, options } = usePagination();
      const { headers } = useDailyLogViewListDatatable();
      const { DailyLogInvoice } = Vue.$FeathersVuex.api;
      const invoiceStatusColor = (invoiced) => {
        if (invoiced === true) return "success";
        if (invoiced === false) return "default";
      };
      const printItem = ref(null);

      const handlePrint = (item) => {
        printItem.value = item;
        setTimeout(() => {
          window.print();
        }, 500);
      };

      const singleSelect = ref(false);
      const selectedItems = ref([]);
      const { processFormData, undoMarkAsInvoiced } = useDailyLogInvoice();

      const handleMarkAsInvoiced = async () => {
        try {
          store.dispatch("daily-log-invoices/updateLoading", true);
          //console.debug("marking as invoiced");
          //console.debug(selectedItems.value);
          processFormData(selectedItems.value);
          // snackbar({
          //   text: `Items successfully marked as 'Invoiced'. Have a nice day!`,
          //   color: "success"
          // });
        } catch (error) {
          snackbar({
            text: `Unable to mark items as invoiced. Please contact your Administrator.`,
            color: "error"
          });
        } finally {
          selectedItems.value = [];
          store.dispatch("daily-log-invoices/updateLoading", false);
        }
      };

      const handleUnmarkAsInvoiced = async (item) => {
        try {
          store.dispatch("daily-log-invoices/updateLoading", true);
          console.debug("unmarking as invoiced");
          console.debug(item);
          undoMarkAsInvoiced(item);
        } catch (error) {
          snackbar({
            text: `Unable to mark items as invoiced. Please contact your Administrator.`,
            color: "error"
          });
        } finally {
          selectedItems.value = [];
          store.dispatch("daily-log-invoices/updateLoading", false);
        }
      };
      watch(options, (value) => {
        store.dispatch("daily-logs-view/updatePagination", value);
      });

      DailyLogInvoice.on("patched", async () => {
        await findDailyLogViews(dailyLogViewsParams);
      });

      const refreshDataTable = async () => {
        await findDailyLogViews(dailyLogViewsParams);
        snackbar({
          text: `Daily Log Views successfully reloaded. Enabled filters have been preserved.`,
          color: "info"
        });
      };

      return {
        rootThemeClasses,
        dailyLogViews,
        dailyLogViewsLoaded,
        dailyLogViewsPending,
        dailyLogViewsLatestQuery,
        refreshDataTable,
        selectedNumberOfItemsPerPage,
        resolveOrganizationLogo,
        page,
        options,
        headers,
        icons: {
          mdiAlertOutline,
          mdiFilterOutline,
          mdiMagnify,
          mdiMapMarkerOutline,
          mdiPrinterOutline,
          mdiRefresh,
          mdiUndoVariant
        },
        appLogo: themeConfig.app.logoDark,
        invoiceStatusColor,
        handlePrint,
        singleSelect,
        selectedItems,
        //handleItemSelected,
        //handleAllItemsSelected,
        handleMarkAsInvoiced,
        printItem,
        handleUnmarkAsInvoiced
      };
    }
  });
</script>
<template>
  <div v-if="dailyLogViewsLoaded">
    <v-card id="invoice-list" class="no-print" flat>
      <v-card-text class="d-flex align-center justify-space-between flex-wrap">
        <!-- create contract -->
        <div class="d-flex title">
          IDRs
          <span class="ml-1" v-if="selectedItems.length > 0">
            ({{ selectedItems.length }})
          </span>
        </div>
        <div class="d-flex">
          <v-btn
            small
            color="primary"
            class="me-3"
            :disabled="selectedItems.length > 0 ? false : true"
            @click="handleMarkAsInvoiced"
          >
            <!-- Use the singular form of `IDR` if only one is selected -->
            <span v-if="selectedItems.length == 1">
              Mark {{ selectedItems.length }} IDR as Invoiced
            </span>
            <!-- Use the plural form of `IDR` if more than one is selected -->
            <span v-else-if="selectedItems.length > 1">
              Mark {{ selectedItems.length }} IDRs as Invoiced
            </span>
            <span v-else>Mark as Invoiced</span>
          </v-btn>
          {{ selectedItems.value }}
          <idr-filter-menu></idr-filter-menu>

          <v-btn
            small
            outlined
            color="secondary"
            class="ml-3"
            @click="refreshDataTable()"
          >
            <v-icon>{{ icons.mdiRefresh }}</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <!-- data table -->
      <v-data-table
        :headers="headers"
        :items="dailyLogViewsLatestQuery.response.data"
        :options.sync="options"
        :server-items-length="dailyLogViewsLatestQuery.response.total"
        :loading="dailyLogViewsPending"
        show-select
        :single-select="singleSelect"
        item-key="fulcrum_id"
        v-model="selectedItems"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50]
        }"
      >
        <!-- name -->
        <template #[`item.idr_date`]="{ item }">
          <div class="d-flex align-center">
            <!-- <div class="v-avatar-group" :class="rootThemeClasses"> -->
            <!-- <v-avatar
                color="red"
                class="v-avatar-light-bg primary--text"
                size="32"
              >
                <span>{{ item.inspector_initials }}</span>
              </v-avatar> -->
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text"
              size="48"
            >
              <v-img
                v-if="item.contractor_name"
                :src="resolveOrganizationLogo(item.contractor_id)"
              ></v-img>
              <span v-else>
                <span>
                  {{ item.contractor_name.slice(0, 2).toUpperCase() }}
                </span>
              </span>
            </v-avatar>
            <!-- </div> -->
            <div v-if="item.fulcrum_id" class="d-flex flex-column ms-3">
              <div class="d-flex flex-row align-center">
                <router-link
                  :to="{
                    name: 'apps-daily-log-view',
                    params: {
                      project: item.project_id,
                      record: item.const_id,
                      idr: item.fulcrum_id
                    }
                  }"
                  class="primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ item.idr_date }}
                </router-link>
                <v-tooltip class="d-flex ml-1" right>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="error"
                      v-if="item.delay_reason !== null"
                      v-on="on"
                      small
                    >
                      {{ icons.mdiAlertOutline }}
                    </v-icon>
                  </template>
                  <span>{{ item.delay_reason }} delay</span>
                </v-tooltip>
              </div>
              <small class="secondary--text">
                <span class="font-weight-semibold">
                  {{ item.permit_num }} -
                </span>
                {{ item.location }}
              </small>
            </div>
          </div>
        </template>

        <!-- status -->
        <template #[`item.invoiced`]="{ item }">
          <v-chip
            small
            :color="invoiceStatusColor(item.invoiced)"
            :class="`${invoiceStatusColor(item.invoiced)}--text`"
            class="v-chip-light-bg"
          >
            {{ item.invoiced ? "Invoiced" : "Not Invoiced" }}
          </v-chip>
        </template>

        <!-- card actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex justify-space-between">
            <v-btn class="d-flex" icon x-small>
              <v-icon color="primary" @click="handlePrint(item)">
                {{ icons.mdiPrinterOutline }}
              </v-icon>
            </v-btn>
            <v-tooltip v-if="item.invoiced === true" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="d-flex" icon x-small v-bind="attrs" v-on="on">
                  <v-icon color="primary" @click="handleUnmarkAsInvoiced(item)">
                    {{ icons.mdiUndoVariant }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Undo Mark as Invoiced</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <print-daily-log-report class="idr-report" :item="printItem" />
  </div>
  <div v-else>
    <app-loading></app-loading>
  </div>
</template>
<style lang="scss">
  .idr-report {
    display: none;
  }
  @media print {
    // .v-application {
    //   background: none !important;
    // }
    .app-navigation-menu,
    .v-app-bar,
    .v-footer {
      display: none !important;
    }
    .v-main,
    .app-content-container {
      padding: 0px !important;
    }
    .no-print {
      display: none !important;
    }
    .idr-report {
      // max-width: 100% !important;
      // flex: 1 !important;
      display: block;
    }
  }
</style>
