<script>
  import {
    mdiCheckboxBlankOutline,
    mdiCheckboxMarkedOutline,
    mdiClose,
    mdiFilterVariant,
    mdiMagnify
  } from "@mdi/js";
  import { defineComponent, watch } from "@vue/composition-api";
  import { watchDebounced } from "@vueuse/core";
  import { PerfectScrollbar } from "vue2-perfect-scrollbar";

  // composables
  import {
    useContractorFilter,
    useFilterMenuToggle,
    useIdrDateFilter,
    useInvoicedFilter,
    useLeadInspectorFilter,
    // useProjectStatusFilter,
    // useProjectTypeFilter,
    useSearchTextFilter
  } from "@/components/common/filter-menu/useFilterMenu";
  import { store } from "@/store";

  export default defineComponent({
    components: {
      PerfectScrollbar
    },

    setup() {
      const {
        isFilterDrawerOpen,
        expandButtonLabel,
        clearFiltersButtonLabel,
        perfectScrollbarOptions,
        filtersPanel,
        filtersLength,
        toggleAll
      } = useFilterMenuToggle();

      const { filterText, resetTextFilter } = useSearchTextFilter();
      const {
        contractorOrganizations,
        selectedContractors,
        selectAllContractorsIcon,
        toggleAllContractors,
        resetContractorFilter
      } = useContractorFilter();

      const {
        selectedIdrDates,
        resetIdrDateFilter,
        idrDateMenu,
        dateRangeText
      } = useIdrDateFilter();

      const {
        leadInspectorContacts,
        groupedLeadInspectorContacts,
        selectedLeadInspectors,
        selectAllLeadInspectorsIcon,
        toggleAllLeadInspectors,
        resetLeadInspectorFilter
      } = useLeadInspectorFilter();

      const { filterInvoiced, resetInvoicedFilter } = useInvoicedFilter();

      const clearAllFilters = () => {
        resetTextFilter();
        resetContractorFilter();
        resetLeadInspectorFilter();
        resetIdrDateFilter();
        resetInvoicedFilter();
        store.dispatch("daily-logs-view/clearAllFilters");
      };

      watchDebounced(
        filterText,
        (value) => {
          store.dispatch("daily-logs-view/filterText", value);
        },
        { debounce: 500 }
      );

      watch(selectedContractors, (value) => {
        store.dispatch("daily-logs-view/filterContractors", value);
      });

      watch(selectedLeadInspectors, (value) => {
        store.dispatch("daily-logs-view/filterLeadInspectors", value);
      });

      watch(selectedIdrDates, (value) => {
        store.dispatch("daily-logs-view/filterIdrDates", value);
      });

      watch(filterInvoiced, (value) => {
        store.dispatch("daily-logs-view/filterInvoiced", value);
      });
      return {
        clearAllFilters,

        // useFilterMenuToggle
        isFilterDrawerOpen,
        expandButtonLabel,
        clearFiltersButtonLabel,
        perfectScrollbarOptions,
        filtersPanel,
        filtersLength,
        toggleAll,

        // useSearchTextFilter
        filterText,
        resetTextFilter,

        // useContractorFilter
        contractorOrganizations,
        selectedContractors,
        selectAllContractorsIcon,
        toggleAllContractors,
        resetContractorFilter,

        // useIdrDateFilter
        selectedIdrDates,
        idrDateMenu,
        resetIdrDateFilter,
        dateRangeText,

        // useInvoicedFilter
        filterInvoiced,
        resetInvoicedFilter,

        // useLeadInspectorFilter
        leadInspectorContacts,
        groupedLeadInspectorContacts,
        selectedLeadInspectors,
        selectAllLeadInspectorsIcon,
        toggleAllLeadInspectors,
        resetLeadInspectorFilter,

        icons: {
          mdiCheckboxBlankOutline,
          mdiCheckboxMarkedOutline,
          mdiClose,
          mdiFilterVariant,
          mdiMagnify
        }
      };
    }
  });
</script>
<template>
  <div class="app-customizer">
    <v-btn
      small
      outlined
      color="primary"
      @click="isFilterDrawerOpen = !isFilterDrawerOpen"
    >
      <v-icon left>{{ icons.mdiFilterVariant }}</v-icon>
      Filter List
    </v-btn>

    <!-- navigation drawer -->
    <v-navigation-drawer
      v-model="isFilterDrawerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="430"
      class="app-customizer-drawer"
    >
      <!-- heading -->
      <div class="app-customizer-header customizer-section py-3">
        <h6 class="font-weight-semibold text-xl text--primary">FILTERS</h6>
        <span class="text--secondary">
          Set your filters and view the results
        </span>
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isFilterDrawerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>
      <!-- filter list content -->
      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!-- text search -->
        <div class="customizer-section">
          <v-text-field
            v-model="filterText"
            :prepend-inner-icon="icons.mdiMagnify"
            clearable
            single-line
            dense
            outlined
            hide-details
            placeholder="Search by contract, proceed, permit, or location"
          ></v-text-field>
        </div>

        <!-- expand/collapse all -->
        <div class="text-center d-flex justify-space-between pb-4">
          <v-btn
            @click="clearAllFilters"
            x-small
            text
            class="text-xs secondary--text"
          >
            {{ clearFiltersButtonLabel }}
          </v-btn>
          <v-btn
            @click="toggleAll"
            x-small
            text
            class="text-xs secondary--text"
          >
            {{ expandButtonLabel }}
          </v-btn>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-start ma-3">
          <v-switch
            v-model="filterInvoiced"
            inset
            label="Show Invoiced Only"
          ></v-switch>
        </div>
        <v-divider></v-divider>
        <v-expansion-panels v-model="filtersPanel" accordion flat multiple tile>
          <!-- idr date -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex align-center justify-space-between">
                <span
                  :class="
                    selectedIdrDates.length > 0
                      ? 'primary--text text-xs font-weight-semibold'
                      : 'text-xs font-weight-semibold'
                  "
                >
                  IDR DATE
                </span>
                <v-chip
                  v-if="selectedIdrDates.length > 1"
                  class="mr-5"
                  close
                  small
                  color="primary"
                  @click:close="resetIdrDateFilter"
                >
                  {{ dateRangeText }}
                </v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-menu
                ref="menuref"
                v-model="idrDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Date Range"
                    hint="YYYY-MM-DD format"
                    persistent-hint
                    readonly
                    outlined
                    dense
                    :prepend-icon="icons.mdiCalendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="selectedIdrDates"
                  no-title
                  range
                  color="primary"
                >
                  <v-btn text color="primary" @click="idrDateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="idrDateMenu = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- contractor multi-select -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex align-center justify-space-between">
                <span
                  :class="
                    selectedContractors.length > 0
                      ? 'primary--text text-xs font-weight-semibold'
                      : 'text-xs font-weight-semibold'
                  "
                >
                  CONTRACTOR
                </span>
                <v-chip
                  v-if="selectedContractors.length > 0"
                  class="mr-5"
                  close
                  small
                  color="primary"
                  @click:close="resetContractorFilter"
                >
                  {{ selectedContractors.length }}
                </v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="my-1">
                <v-select
                  v-model="selectedContractors"
                  :items="contractorOrganizations"
                  item-value="fulcrum_id"
                  item-text="short_name"
                  label="Select Contractor(s)"
                  multiple
                  outlined
                  dense
                >
                  <template #prepend-item>
                    <v-list-item ripple @click="toggleAllContractors">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedContractors.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{ selectAllContractorsIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-2"></v-divider>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item.short_name }}</span>

                    <span v-if="index === 1" class="grey--text text-caption">
                      &nbsp;(+{{ selectedContractors.length - 1 }} more)
                    </span>
                  </template>
                </v-select>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- lead inspector -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex align-center justify-space-between">
                <span
                  :class="
                    selectedLeadInspectors.length > 0
                      ? 'primary--text text-xs font-weight-semibold'
                      : 'text-xs font-weight-semibold'
                  "
                >
                  LEAD INSPECTOR
                </span>
                <v-chip
                  v-if="selectedLeadInspectors.length > 0"
                  class="mr-5"
                  close
                  small
                  color="primary"
                  @click:close="resetLeadInspectorFilter"
                >
                  {{ selectedLeadInspectors.length }}
                </v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="my-1">
                <v-select
                  v-if="groupedLeadInspectorContacts"
                  v-model="selectedLeadInspectors"
                  :items="groupedLeadInspectorContacts"
                  item-value="fulcrum_id"
                  :menu-props="{ maxHeight: '400' }"
                  label="Select Lead Inspector(s)"
                  multiple
                  outlined
                  dense
                  class="mt-3"
                >
                  <template #prepend-item>
                    <v-list-item ripple @click="toggleAllLeadInspectors">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedLeadInspectors.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{ selectAllLeadInspectorsIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open
                         NOTE: We have to handle the checkbox state manually
                    -->
                    <v-list-item-action>
                      <v-icon v-if="data.attrs.inputValue">
                        {{ icons.mdiCheckboxMarkedOutline }}
                      </v-icon>
                      <v-icon v-else>
                        {{ icons.mdiCheckboxBlankOutline }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.first_name }} {{ data.item.last_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.header }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">
                      {{ item.first_name }} {{ item.last_name }}
                    </span>
                    <span v-if="index === 1" class="grey--text text-caption">
                      &nbsp;(+{{ selectedLeadInspectors.length - 1 }} more)
                    </span>
                    <!-- <span v-if="index === 1" class="grey--text text-caption">
                      &nbsp;(+{{ selectedLeadInspectors.length - 1 }} more)
                    </span> -->
                  </template>
                </v-select>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>
<style lang="scss">
  @import "~vuetify/src/styles/styles.sass";

  .ps-customizer {
    height: calc(100% - 81px) !important;
  }

  .app-customizer-toggler {
    position: fixed;
    top: 50%;
    transform: translateX(-50%);
    background: var(--v-primary-base);
    @include ltr() {
      right: -22px;
    }
    @include rtl() {
      left: 20px;
    }
  }

  @include theme(app-customizer-drawer) using ($material) {
    background-color: map-deep-get($material, "cards");
  }

  .app-customizer {
    z-index: 7;

    .v-label {
      font-size: 0.875rem;
    }

    .app-customizer-header {
      position: relative;
      .icon-customizer-close {
        position: absolute;
        @include ltr() {
          right: 20px;
        }
        @include rtl() {
          left: 20px;
        }
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .customizer-section {
      padding: 15px;
    }

    // Fixes Overlay is shown below SystemBar
    @at-root {
      .v-overlay {
        & + .v-application--wrap > .v-system-bar {
          z-index: 6 !important;
        }
      }
    }
  }

  .v-application.theme--light {
    .v-expansion-panel {
      &::before {
        box-shadow: none !important;
      }
      &--active {
        box-shadow: none !important;
      }
    }
  }
</style>
