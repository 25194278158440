<script>
  import { mdiCheckboxBlankOutline, mdiCheckboxOutline } from "@mdi/js";
  import { computed, defineComponent } from "@vue/composition-api";

  //import { useElementBounding } from "@vueuse/core";
  import themeConfig from "@/themeConfig";

  export default defineComponent({
    props: {
      item: {
        type: Object,
        required: false
      }
    },

    setup(props) {
      // Format date as MM/DD/YYYY
      const dateFormat = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      };

      const personnelAndEquipment = [
        "Journeyman",
        "Electrician",
        "Equipment Operator",
        "Masonry",
        "Skilled Laborer",
        "Unskilled Laborer",
        "Flagger",
        "Traffic Officer",
        "TMA",
        "Auger Truck",
        "Bucket Truck",
        "Pole Truck",
        "Backhoe",
        "Trencher",
        "Compressor",
        "Road Saw",
        "Tractor",
        "Arrow Board",
        "Tamp",
        "Concrete Truck",
        "Dump Truck",
        "Boring Machine"
      ];

      //const idr_comment = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
      const formatCurrentDate = (format = dateFormat) => {
        let currentDate = new Date();
        return new Intl.DateTimeFormat("en-US", format).format(currentDate);
      };

      const numLineItems = computed(() => {
        return props.item.idr_items.length;
      });

      // const commentSection = ref(null);
      // const { height } = useElementBounding(commentSection);
      const commentSectionWordCount = computed(() => {
        // if idr_comment is not null, get the word count of
        // the comment section
        if (props.item.idr_comment !== null) {
          return props.item.idr_comment.split(" ").length;
        } else {
          return 0;
        }
      });

      const shouldPageBreakCommentSection = computed(() => {
        if (numLineItems.value >= 20) {
          return true;
        } else if (numLineItems.value < 10) {
          return false;
        } else if (
          numLineItems.value > 10 &&
          numLineItems.value < 16 &&
          commentSectionWordCount.value > 36
        ) {
          return true;
        }
      });
      const shouldPageBreakSignatureSection = computed(() => {
        if (shouldPageBreakCommentSection.value === true) {
          return false;
        } else if (
          numLineItems.value < 10 &&
          commentSectionWordCount.value > 200
        ) {
          return true;
        } else if (
          numLineItems.value <= 10 &&
          commentSectionWordCount.value > 36
        ) {
          return true;
        } else if (numLineItems.value > 10) {
          return true;
        } else if (
          numLineItems.value > 10 &&
          numLineItems.value < 16 &&
          commentSectionWordCount.value > 36
        ) {
          return true;
        }
      });

      const formatDate = (date) => {
        const [year, month, day] = date.split("-");
        return `${month}/${day}/${year}`;
      };
      return {
        reportAgency: themeConfig.reporting.tsAgency,
        reportDept: themeConfig.reporting.tsDept,
        reportTitle: themeConfig.reporting.tsTitle,
        appLogo: themeConfig.app.logoDark,
        icons: {
          mdiCheckboxBlankOutline,
          mdiCheckboxOutline
        },
        dateFormat,
        formatDate,
        formatCurrentDate,
        personnelAndEquipment,
        numLineItems,
        //commentSection,
        commentSectionWordCount,
        //height,
        //idr_comment,
        shouldPageBreakCommentSection,
        shouldPageBreakSignatureSection
      };
    }
  });
</script>
<template>
  <v-row v-if="item" class="app-invoice-preview">
    <v-col cols="12">
      <div class="invoice-header d-flex flex-wrap justify-space-between">
        <!-- Left Content -->
        <div class="mb-0">
          <div id="image-logo" class="d-flex align-center my-3"></div>
        </div>
        <!-- Center Content-->
        <div class="mb-0">
          <div class="d-flex align-center flex-column mb-6">
            <span class="text--primary text-bold text-xl">
              {{ reportAgency }}
            </span>
            <span class="text--primary text-xl">Traffic Section</span>
            <span class="text--primary text-xl">
              Signal Construction Division
            </span>
            <span class="text--primary font-weight-semibold text-xl">
              Inspector's Daily Report
            </span>
          </div>
        </div>
        <!-- Right Content -->
        <div class="mb-0">
          <div class="d-flex flex-column mb-6">
            <v-card class="pa-2" outlined>
              <span class="d-block">
                <v-icon v-if="item.const_type[0] === 'Signal'">
                  {{ icons.mdiCheckboxOutline }}
                </v-icon>
                <v-icon v-else>
                  {{ icons.mdiCheckboxBlankOutline }}
                </v-icon>
                <span
                  :class="
                    item.const_type[0] === 'Signal'
                      ? 'font-weight-semibold'
                      : 'font-weight-regular'
                  "
                  >&nbsp;Signal
                </span>
              </span>
              <span class="d-block">
                <v-icon v-if="item.const_type[0] === 'Pave and Rehab'">
                  {{ icons.mdiCheckboxOutline }}
                </v-icon>
                <v-icon v-else>
                  {{ icons.mdiCheckboxBlankOutline }}
                </v-icon>
                <span
                  :class="
                    item.const_type[0] === 'Pave and Rehab'
                      ? 'font-weight-semibold'
                      : 'font-weight-regular'
                  "
                  >&nbsp;P&R
                </span>
              </span>
              <span class="d-block">
                <v-icon v-if="item.const_type[0] === 'ITS'">
                  {{ icons.mdiCheckboxOutline }}
                </v-icon>
                <v-icon v-else>
                  {{ icons.mdiCheckboxBlankOutline }}
                </v-icon>
                <span
                  :class="
                    item.const_type[0] === 'ITS'
                      ? 'font-weight-semibold'
                      : 'font-weight-regular'
                  "
                  >&nbsp;ITS
                </span>
              </span>
              <span class="d-block">
                <v-icon v-if="item.const_type[0] === 'Lighting'">
                  {{ icons.mdiCheckboxOutline }}
                </v-icon>
                <v-icon v-else>
                  {{ icons.mdiCheckboxBlankOutline }}
                </v-icon>
                <span
                  :class="
                    item.const_type[0] === 'Lighting'
                      ? 'font-weight-semibold'
                      : 'font-weight-regular'
                  "
                  >&nbsp;Lighting
                </span>
              </span>
            </v-card>
          </div>
        </div>
      </div>

      <div class="invoice-details">
        <div class="d-flex justify-space-between flex-wrap">
          <div class="d-flex flex-column mb-4">
            <div class="d-flex justify-space-between flex-wrap">
              <div class="d-flex flex-column mb-4">
                <span class="font-weight-regular"> Permit No: </span>
                <span class="font-weight-regular"> Location: </span>
                <span class="font-weight-regular"> Contract No: </span>
                <span class="font-weight-regular"> Proceed No: </span>
              </div>
              <div class="d-flex flex-column">
                <span class="font-weight-semibold ml-2">
                  {{ item.permit_num }}
                </span>
                <span class="font-weight-semibold ml-2">
                  {{ item.location }}
                </span>
                <span class="font-weight-semibold ml-2">
                  {{ item.contract_num }}
                </span>
                <span class="font-weight-semibold ml-2">
                  {{ item.proceed_num }}
                </span>
              </div>
            </div>
          </div>
          <!-- <div class="font-weight-regular">
              Permit No:
              <span class="font-weight-semibold">
                {{ item.permit_num }}
              </span>
            </div>
            <div class="font-weight-regular">
              Location:
              <span class="font-weight-semibold">
                {{ item.location }}
              </span>
            </div>
            <div class="font-weight-regular">
              Contract No:
              <span class="font-weight-semibold">{{ item.contract_num }}</span>
            </div>
            <div class="font-weight-regular">
              Proceed No:
              <span class="font-weight-semibold">{{ item.proceed_num }}</span>
            </div>
          </div> -->
          <div class="d-flex flex-column mb-4">
            <div class="d-flex justify-space-between flex-wrap">
              <div class="d-flex flex-column mb-4">
                <span class="font-weight-regular"> Inspection Date: </span>
                <span class="font-weight-regular"> Prime Contractor: </span>
                <span class="font-weight-regular"> Temperature: </span>
                <span class="font-weight-regular"> Weather: </span>
              </div>
              <div class="d-flex flex-column">
                <span class="font-weight-semibold ml-2">
                  {{ formatDate(item.idr_date) }}
                </span>
                <span class="font-weight-semibold ml-2">
                  {{ item.contractor_name }}
                </span>
                <span class="font-weight-semibold ml-2">
                  {{ item.temperature }}
                </span>
                <span class="font-weight-semibold ml-2">
                  {{ item.weather }}
                </span>
              </div>
            </div>
            <!-- <div class="font-weight-regular">
              Inspection Date:
              <span class="font-weight-semibold">
                {{ item.idr_date }}
              </span>
            </div>
            <div class="font-weight-regular">
              Prime Contractor:
              <span class="font-weight-semibold">
                {{ item.contractor_name }}
              </span>
            </div>
            <div class="font-weight-regular">
              Weather:
              <span class="font-weight-semibold">{{ item.weather }}</span>
            </div>
            <div class="font-weight-regular">
              Temperature:
              <span class="font-weight-semibold">{{ item.temperature }}</span>
            </div> -->
          </div>
        </div>
        <div>
          <v-card
            class="section-header font-weight-semibold text-lg"
            color="#164883"
            dark
            tile
          >
            Item Summary - Quantities Completed
          </v-card>
        </div>
        <v-simple-table v-if="item.idr_items.length > 0" dense>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">Item</th>
                <th class="text-left">Description</th>
                <th class="text-left">UOM</th>
                <th class="text-right">Qty</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="idr in item.idr_items" :key="idr.item_num">
                <td>{{ idr.item_num }}</td>
                <td>{{ idr.item_desc }}</td>
                <td>{{ idr.item_unit }}</td>
                <td class="text-right">{{ idr.item_qty }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-else>
          <div class="d-flex justify-center align-center">
            <span class="text--primary font-weight-semibold text-lg">
              No Items Recorded
            </span>
          </div>
        </div>
        <div :class="item.idr_items.length >= 16 ? 'pagebreak' : 'nopagebreak'">
          <v-card
            class="section-header font-weight-semibold text-lg mt-3"
            color="#164883"
            dark
            tile
          >
            Contractor Personnel & Equipment
          </v-card>
        </div>
        <table
          v-if="item.idr_personnel_equipment.length > 0"
          class="pers-equip-table text-xs"
        >
          <thead>
            <tr>
              <th></th>
              <th v-for="item in personnelAndEquipment" :key="item">
                <div class="rotated-th">
                  <span class="rotated-th__label">{{ item }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="pers-equip-tbody text-sm">
            <tr
              v-for="equip in item.idr_personnel_equipment"
              :key="equip.contractor_name"
              class="text-sm"
            >
              <th
                scope="row"
                class="text-sm"
                v-if="equip.foreman_name !== null"
              >
                {{ equip.contractor_name }}/<br />{{ equip.foreman_name }}
              </th>
              <th scope="row" class="text-sm" v-else>
                {{ equip.contractor_name }}
              </th>
              <td class="">{{ equip.journeyman_qty }}</td>
              <td>{{ equip.electrician_qty }}</td>
              <td>{{ equip.operator_qty }}</td>
              <td>{{ equip.masonry_qty }}</td>
              <td>{{ equip.skilled_laborer_qty }}</td>
              <td>{{ equip.unskilled_laborer_qty }}</td>
              <td>{{ equip.flagger_qty }}</td>
              <td>{{ equip.traffic_officer_qty }}</td>
              <td>{{ equip.tma_qty }}</td>
              <td>{{ equip.auger_truck_qty }}</td>
              <td>{{ equip.bucket_truck_qty }}</td>
              <td>{{ equip.pole_truck_qty }}</td>
              <td>{{ equip.backhoe_qty }}</td>
              <td>{{ equip.trencher_qty }}</td>
              <td>{{ equip.compressor_qty }}</td>
              <td>{{ equip.road_saw_qty }}</td>
              <td>{{ equip.tractor_qty }}</td>
              <td>{{ equip.arrow_board_qty }}</td>
              <td>{{ equip.tamp_qty }}</td>
              <td>{{ equip.concrete_truck_qty }}</td>
              <td>{{ equip.dump_truck_qty }}</td>
              <td>{{ equip.boring_machine_qty }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <div class="d-flex justify-center align-center">
            <span class="text--primary font-weight-semibold text-lg">
              No Personnel or Equipment Recorded
            </span>
          </div>
        </div>
        <div :class="item.idr_items.length > 14 ? 'pagebreak' : 'nopagebreak'">
          <v-card
            class="section-header font-weight-semibold text-sm mt-8"
            color="#164883"
            dark
            tile
          >
            Additional Comments - Use sketches if required<br />
            (Highlight work performed, quantities, and company)
          </v-card>

          <!-- <div class="d-flex mb-8">{{ item.idr_comment }}</div> -->
          <div v-if="item.idr_comment" id="comment-content" class="d-flex mb-8">
            {{ item.idr_comment }}
          </div>
          <div v-else>
            <div class="d-flex justify-center align-center">
              <span class="text--primary font-weight-semibold text-lg">
                Comments Not Recorded
              </span>
            </div>
          </div>
        </div>
        <div
          :class="
            shouldPageBreakSignatureSection
              ? 'pagebreak d-flex align-content-center text-xs mt-5'
              : 'd-flex align-content-center text-xs mt-5'
          "
        >
          <table class="tablestyle">
            <tbody>
              <tr>
                <td class="info-block" rowspan="2">
                  <div class="d-flex flex-column text-sm my-3">
                    <div class="font-weight-regular">
                      ORIGINAL - DelDOT File
                    </div>
                    <div class="font-weight-regular">YELLOW - Contractor</div>
                    <div class="font-weight-regular">PINK - Inspector</div>
                    <div class="font-weight-regular mt-3">Generated by:</div>
                    <div class="d-flex justify-center align-center mt-1">
                      <div id="image-logo-sm" class="d-flex"></div>
                      <div class="d-flex font-weight-bold">TrafficPM</div>
                    </div>
                    <div class="font-weight-semibold mt-3">
                      {{ formatCurrentDate(dateFormat) }}
                    </div>
                  </div>
                </td>
                <td colspan="9">
                  <div class="signature-block font-weight-semibold">
                    SIGNATURES
                  </div>
                  <div>
                    <v-row>
                      <v-col cols="9" class="flex-grow-1 flex-shrink-0">
                        <v-text-field
                          dense
                          class="text-xs"
                          hint="DelDOT Inspector"
                          persistent-hint
                          :label="`${item.inspector_name}`"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" class="flex-grow-0 flex-shrink-0">
                        <v-text-field
                          dense
                          class="text-xs"
                          hint="Date"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-n8">
                      <v-col cols="9" class="flex-grow-1 flex-shrink-0">
                        <v-text-field
                          dense
                          class="text-xs"
                          hint="Contractor Rep"
                          persistent-hint
                          label=""
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" class="flex-grow-0 flex-shrink-0">
                        <v-text-field
                          dense
                          class="text-xs"
                          hint="Date"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </td>
                <td class="info-block" rowspan="2"></td>
              </tr>
              <tr>
                <td colspan="9">
                  <div>
                    <v-row>
                      <v-col cols="7" class="flex-grow-1 flex-shrink-0">
                        <span class="font-weight-semibold text-xs">
                          Construction Delay Summary:
                        </span>
                        <v-divider class="my-3"></v-divider>
                        <v-divider class="mb-3"></v-divider>
                        <v-divider class="mb-3"></v-divider>
                      </v-col>
                      <v-col cols="5" class="flex-grow-0 flex-shrink-1">
                        <span class="font-weight-semibold text-xs">
                          Approved Credit:
                        </span>
                        <v-divider class="my-3"></v-divider>
                        <v-divider class="mb-3"></v-divider>
                        <v-divider class="mb-3"></v-divider>
                      </v-col>
                    </v-row>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<style scoped lang="scss">
  @media print {
    .app-invoice-preview {
      widows: 3;
      orphans: 3;
    }
    .pagebreak {
      page-break-before: always;
    }

    // .fix-to-bottom {
    //   position: absolute;
    //   bottom: -200;
    // }
    #image-logo {
      content: url("../../../assets/images/svg/deldot.svg");
      width: 100px;
      height: 100px;
    }
    #image-logo-sm {
      content: url("../../../assets/images/svg/deldot.svg");
      width: 30px;
      height: 30px;
    }
    // .rotate-height {
    //   height: 140px;
    //   white-space: nowrap;
    // }

    .section-header {
      text-align: center;
      print-color-adjust: exact !important;
      -webkit-print-color-adjust: exact !important;
    }

    // Personnel and Equipment Table CSS
    .pers-equip-table {
      border-collapse: collapse;
    }

    .pers-equip-tbody th {
      padding: 4px 15px 4px 0px;
      text-align: right;
    }

    .pers-equip-tbody td {
      border: 1px solid #cccccc;
      padding: 8px 15px 8px 15px;
      text-align: center;
    }

    .rotated-th {
      /**
			* Since the rotated text is taken out of the DOM flow (position: absolute), we
			* need to artificially consume vertical space so that the rotated headers
			* don't overlap with the content above the table.
			*/
      height: 155px;
      position: relative;
    }
    /**
		* When an element is transform rotated, it still takes up the amount of space that
		* it would have if not rotated. As such, I'm making the label "position: absolute"
		* so that it doesn't take up any space ("absolute" takes it out of the DOM flow).
		* Instead, I'm deferring the space allocation to the parent DIV.
		*/
    .rotated-th__label {
      bottom: 5px;
      left: 50%;
      position: absolute;
      transform: rotate(-90deg);
      transform-origin: center left;
      white-space: nowrap;
    }

    // Signature Block CSS
    .tablestyle {
      //border: 2px solid;
      flex: 1;
    }

    .tablestyle td {
      border: 1px solid;
      padding: 3px;
      height: 25px;
    }

    .tablestyle td h4 {
      margin-bottom: 0;
    }

    .info-block {
      width: 200px;
      text-align: center;
    }

    .signature-block {
      text-align: center;
    }
  }
</style>
