import Vue from "vue";

import { snackbar } from "@/components/base/snackbar/useSnackbar";
import { store } from "@/store";

const { DailyLogInvoice } = Vue.$FeathersVuex.api;

export function useDailyLogInvoice() {
  const processFormData = async (itemToEdit) => {
    try {
      //console.log(itemToEdit);
      itemToEdit.forEach((item) => {
        // let dailyLogInvoiceData = {
        //   fulcrum_id: item.fulcrum_id,
        //   invoiced_by: store.state["auth"].user.id,
        //   invoiced_on: null
        // };
        let dailyLogInvoice = new DailyLogInvoice({
          fulcrum_id: item.fulcrum_id,
          invoiced_by: store.state["auth"].user.id,
          invoiced_on: new Date()
        });
        // console.log(dailyLogInvoice);
        dailyLogInvoice.patch().then(() => {});
      });

      snackbar({
        text: `Items successfully marked as 'Invoiced'.`,
        color: "success"
      });
    } catch (error) {
      snackbar({
        text: `Unable to mark items as invoiced. Please contact your Administrator.`,
        color: "error"
      });
    } finally {
      store.dispatch("daily-log-invoices/updateLoading", false);
      // snackbar({
      //   text: `Successfully updated.`,
      //   color: "success"
      // });
    }
  };

  const undoMarkAsInvoiced = async (itemToEdit) => {
    try {
      let dailyLogInvoice = new DailyLogInvoice({
        fulcrum_id: itemToEdit.fulcrum_id,
        invoiced_by: null,
        invoiced_on: null
      });
      await dailyLogInvoice.patch();
    } catch (error) {
      snackbar({
        text: `Unable to undo mark as invoiced. Please contact your Administrator.`,
        color: "error"
      });
    } finally {
      store.dispatch("daily-log-invoices/updateLoading", false);
    }
  };
  return {
    processFormData,
    undoMarkAsInvoiced
  };
}
