var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dailyLogViewsLoaded)?_c('div',[_c('v-card',{staticClass:"no-print",attrs:{"id":"invoice-list","flat":""}},[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between flex-wrap"},[_c('div',{staticClass:"d-flex title"},[_vm._v(" IDRs "),(_vm.selectedItems.length > 0)?_c('span',{staticClass:"ml-1"},[_vm._v(" ("+_vm._s(_vm.selectedItems.length)+") ")]):_vm._e()]),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"me-3",attrs:{"small":"","color":"primary","disabled":_vm.selectedItems.length > 0 ? false : true},on:{"click":_vm.handleMarkAsInvoiced}},[(_vm.selectedItems.length == 1)?_c('span',[_vm._v(" Mark "+_vm._s(_vm.selectedItems.length)+" IDR as Invoiced ")]):(_vm.selectedItems.length > 1)?_c('span',[_vm._v(" Mark "+_vm._s(_vm.selectedItems.length)+" IDRs as Invoiced ")]):_c('span',[_vm._v("Mark as Invoiced")])]),_vm._v(" "+_vm._s(_vm.selectedItems.value)+" "),_c('idr-filter-menu'),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.refreshDataTable()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiRefresh))])],1)],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dailyLogViewsLatestQuery.response.data,"options":_vm.options,"server-items-length":_vm.dailyLogViewsLatestQuery.response.total,"loading":_vm.dailyLogViewsPending,"show-select":"","single-select":_vm.singleSelect,"item-key":"fulcrum_id","footer-props":{
        itemsPerPageOptions: [5, 10, 25, 50]
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.idr_date",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text",attrs:{"color":"primary","size":"48"}},[(item.contractor_name)?_c('v-img',{attrs:{"src":_vm.resolveOrganizationLogo(item.contractor_id)}}):_c('span',[_c('span',[_vm._v(" "+_vm._s(item.contractor_name.slice(0, 2).toUpperCase())+" ")])])],1),(item.fulcrum_id)?_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('router-link',{staticClass:"primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{
                  name: 'apps-daily-log-view',
                  params: {
                    project: item.project_id,
                    record: item.const_id,
                    idr: item.fulcrum_id
                  }
                }}},[_vm._v(" "+_vm._s(item.idr_date)+" ")]),_c('v-tooltip',{staticClass:"d-flex ml-1",attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [(item.delay_reason !== null)?_c('v-icon',_vm._g({attrs:{"color":"error","small":""}},on),[_vm._v(" "+_vm._s(_vm.icons.mdiAlertOutline)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.delay_reason)+" delay")])])],1),_c('small',{staticClass:"secondary--text"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.permit_num)+" - ")]),_vm._v(" "+_vm._s(item.location)+" ")])]):_vm._e()],1)]}},{key:"item.invoiced",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.invoiceStatusColor(item.invoiced)) + "--text"),attrs:{"small":"","color":_vm.invoiceStatusColor(item.invoiced)}},[_vm._v(" "+_vm._s(item.invoiced ? "Invoiced" : "Not Invoiced")+" ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"d-flex",attrs:{"icon":"","x-small":""}},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handlePrint(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPrinterOutline)+" ")])],1),(item.invoiced === true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex",attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleUnmarkAsInvoiced(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiUndoVariant)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Undo Mark as Invoiced")])]):_vm._e()],1)]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_c('print-daily-log-report',{staticClass:"idr-report",attrs:{"item":_vm.printItem}})],1):_c('div',[_c('app-loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }