import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { useRouter } from "@/helpers";
import { store } from "@/store";
const { DailyLogView } = Vue.$FeathersVuex.api;

export function useDailyLogViewList() {
  const dailyLogViewsParams = computed(() => {
    let q = {
      query: {
        $limit: store.state["daily-logs-view"].pageLimit,
        $skip: store.state["daily-logs-view"].pageSkip,
        $sort: {
          idr_date: -1
        }
      },
      $graphql: {
        idr_items: true,
        idr_personnel_equipment: true
      }
    };
    let $and = [];
    // conditionally add/remove filters
    // if the array has a length of 0 then we do not push it to
    // the `q.query.$and` array.
    // if the array is already in `q.query.$and` and has a length
    // of 0 then we must remove it.
    // if the array has a length > 0 then we push it to the
    // `q.query.$and` array.
    // if the array has a length > 0 and is already in `q.query.$and`
    // ....?
    if (store.state["daily-logs-view"].filterText !== null) {
      $and = [
        {
          $or: [
            {
              permit_num: {
                $iLike: store.state["daily-logs-view"].filterText + "%"
              }
            },
            {
              location: {
                $iLike: store.state["daily-logs-view"].filterText + "%"
              }
            },
            {
              contract_num: {
                $iLike: store.state["daily-logs-view"].filterText + "%"
              }
            },
            {
              proceed_num: {
                $iLike: store.state["daily-logs-view"].filterText + "%"
              }
            }
          ]
        }
      ];
    }
    if (store.state["daily-logs-view"].filterContractors.length > 0) {
      $and.push({
        contractor_id: {
          $in: store.state["daily-logs-view"].filterContractors
        }
      });
    }
    if (store.state["daily-logs-view"].filterLeadInspectors.length > 0) {
      $and.push({
        inspector_id: {
          $in: store.state["daily-logs-view"].filterLeadInspectors
        }
      });
    }
    // if we're not filtering by invoiced then we want to show all
    if (store.state["daily-logs-view"].filterInvoiced === false) {
      $and.push({
        $or: [
          {
            invoiced: false
          },
          {
            invoiced: true
          }
        ]
      });
    }
    // if we are filtering by invoiced then we want to show only those
    if (store.state["daily-logs-view"].filterInvoiced === true) {
      $and.push({
        invoiced: true
      });
    }
    if (store.state["daily-logs-view"].filterIdrDates.length > 1) {
      $and.push({
        idr_date: {
          $and: [
            { $gte: store.state["daily-logs-view"].filterIdrDates[0] },
            { $lte: store.state["daily-logs-view"].filterIdrDates[1] }
          ]
        }
      });
    }
    Object.assign(q.query, { $and });
    // console.log(q);
    return q;
  });

  const {
    items: dailyLogViews,
    haveLoaded: dailyLogViewsLoaded,
    isPending: dailyLogViewsPending,
    latestQuery: dailyLogViewsLatestQuery,
    find: findDailyLogViews
  } = useFind({
    model: DailyLogView,
    params: dailyLogViewsParams
  });

  return {
    dailyLogViews,
    dailyLogViewsLoaded,
    dailyLogViewsPending,
    dailyLogViewsLatestQuery,
    findDailyLogViews,
    dailyLogViewsParams
  };
}

export function useDailyLogViewListDatatable() {
  const headers = [
    { text: "", value: "idr_date", sortable: false },
    { text: "INVOICED", align: "center", value: "invoiced", sortable: false },
    {
      text: "PROJECT NUMBER",
      align: "center",
      value: "proceed_num",
      sortable: false
    },
    //{ text: "PERMIT NUMBER", value: "permit_num" },
    {
      text: "PAY ITEMS",
      align: "center",
      value: "item_count",
      sortable: false
    },
    {
      text: "PERS & EQUIP",
      align: "center",
      value: "pers_equip_count",
      sortable: false
    },
    {
      text: "INSPECTOR",
      align: "center",
      value: "inspector_name",
      sortable: false
    },
    { text: "", align: "center", value: "actions", sortable: false }
  ];

  return { headers };
}

export function useDailyLogViewListById() {
  const { route } = useRouter();
  const dailyLogViewListByIdParams = computed(() => {
    let q = {
      query: {
        $limit: store.state["daily-logs-view"].pageLimit,
        $skip: store.state["daily-logs-view"].pageSkip,
        const_id: route.value.params.record,
        $sort: {
          idr_date: -1
        }
      },
      $graphql: {
        idr_items: true,
        idr_personnel_equipment: true
      }
    };
    let $and = [];
    // conditionally add/remove filters
    // if the array has a length of 0 then we do not push it to
    // the `q.query.$and` array.
    // if the array is already in `q.query.$and` and has a length
    // of 0 then we must remove it.
    // if the array has a length > 0 then we push it to the
    // `q.query.$and` array.
    // if the array has a length > 0 and is already in `q.query.$and`
    // ....?
    if (store.state["daily-logs-view"].filterContractors.length > 0) {
      $and.push({
        contractor_id: {
          $in: store.state["daily-logs-view"].filterContractors
        }
      });
    }
    if (store.state["daily-logs-view"].filterLeadInspectors.length > 0) {
      $and.push({
        inspector_id: {
          $in: store.state["daily-logs-view"].filterLeadInspectors
        }
      });
    }
    // if we're not filtering by invoiced then we want to show all
    if (store.state["daily-logs-view"].filterInvoiced === false) {
      $and.push({
        $or: [
          {
            invoiced: false
          },
          {
            invoiced: true
          }
        ]
      });
    }
    // if we are filtering by invoiced then we want to show only those
    if (store.state["daily-logs-view"].filterInvoiced === true) {
      $and.push({
        invoiced: true
      });
    }
    if (store.state["daily-logs-view"].filterIdrDates.length > 1) {
      $and.push({
        idr_date: {
          $and: [
            { $gte: store.state["daily-logs-view"].filterIdrDates[0] },
            { $lte: store.state["daily-logs-view"].filterIdrDates[1] }
          ]
        }
      });
    }
    Object.assign(q.query, { $and });
    // console.log(q);
    return q;
  });

  const {
    items: dailyLogViewsById,
    haveLoaded: dailyLogViewsByIdLoaded,
    isPending: dailyLogViewsByIdPending,
    latestQuery: dailyLogViewsByIdLatestQuery,
    find: findDailyLogViewsById
  } = useFind({
    model: DailyLogView,
    params: dailyLogViewListByIdParams
  });

  return {
    dailyLogViewsById,
    dailyLogViewsByIdLoaded,
    dailyLogViewsByIdPending,
    dailyLogViewsByIdLatestQuery,
    findDailyLogViewsById,
    dailyLogViewListByIdParams
  };
}

export function useDailyLogViewListByIdDatatable() {
  const headers = [
    { text: "DATE", value: "idr_date", sortable: false },
    { text: "INVOICED", align: "center", value: "invoiced", sortable: false },
    //{ text: "PERMIT NUMBER", value: "permit_num" },
    {
      text: "PAY ITEMS",
      align: "center",
      value: "item_count",
      sortable: false
    },
    {
      text: "INSPECTOR",
      align: "center",
      value: "inspector_name",
      sortable: false
    }
  ];

  return { headers };
}
